import './footer.css';

function Footer() {
    return (
        <div className='container'>
            <div className="footer">
                <div className="footer__dispetcherPhone-wrap">
                    <p className="footer__dispetcherPhone-info">Бронирование мест по телефону:</p>
                    <div className="footer__dispetcherPhone-phone-wrap">
                        <a className="footer_dispetcherPhone-phone" href="tel:+375295331133">+375 29 533-11-33</a>
                    </div>
                </div>
                <div className='footer__about'>
                    <p className='footer__about-text'>223053, Минская обл., Минский р-н, д.Боровляны, ул.40 лет Победы, д. 34, оф. 242-6Б УНП 691789032 Лицензия №02190/1-85472 от 24.08.2023</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;